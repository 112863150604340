<template>
  <div>
    <profileTabs />
    <router-view />
  </div>
</template>
<script>
import profileTabs from './profileTabs.vue'

export default {
  components: {
    profileTabs,
  },
}
</script>
